import { helper } from '@ember/component/helper';

export function experienceStyle(params: any /*, hash*/) {
  if (params[0] && params[1]) {
    return `${Math.floor((params[0] / params[1]) * 100)}%`;
  } else {
    return '0%';
  }
}

export default helper(experienceStyle);
