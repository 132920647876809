import Controller from '@ember/controller';
import { observes } from '@ember-decorators/object';
import { computed, action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { next } from '@ember/runloop';

import { ComponentInstance, CabinetMaterial, CabinetStructure } from './types';
import AuthService from '../services/auth';
import { BehaviorSubject, debounceTime } from 'rxjs';
const localStoragePrefix = 'fimab';
// Default-Konfiguration fuer Development

export default class Konfigurator extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify: any;
  @service('order') orderService: any;
  @service('auth') authService!: AuthService;
  @service() calculation: any;

  // Default-Konfiguration fuer Development

  successMessageAngebot = false;
  _cabinetConfiguration = null;

  queryParams: string[] = ['step', 'finished'];
  finished: boolean = false;

  step: string = '1';

  showNext: boolean = false;

  modalOpen: boolean = true;

  showAccountCreate: boolean = false;

  exportXMLData: string = '';
  exportXMLModal: boolean = false;

  isLoading: boolean = true;

  stepsDone: object = {
    step1: false,
    step2: false,
    step3: false
  };

  toggle: boolean = false;

  montageplattebreite: number = 0;
  montageplattehoehe: number = 0;

  private readonly saveQueue$ = new BehaviorSubject(null);

  @computed(
    'toggle',
    'model.cabinet',
    'model.color',
    'model.moduldefinition.width',
    'model.moduldefinition.height',
    'model.moduldefinition.length',
    'model.name',
    'model.moduldefinition.modelKey',
    'montageplattebreite',
    'montageplattehoehe'
  )
  get cabinetConfiguration() {
    if (this.model.cabinet == undefined) {
      return false;
    }

    let count = 0;

    this.set('model.moduldefinition.sides.VORNE', {
      type: 'VORNE',
      minX: this.model.cabinet.restrictedAreas.VORNE.LINKS,
      minY: this.model.cabinet.restrictedAreas.VORNE.OBEN,
      maxX: this.model.moduldefinition.width - this.model.cabinet.restrictedAreas.VORNE.RECHTS,
      maxY: this.model.moduldefinition.height - this.model.cabinet.restrictedAreas.VORNE.OBEN,
      width: parseInt(this.model.moduldefinition.width),
      height: parseInt(this.model.moduldefinition.height),
      image: '',
      components: this.get('model.moduldefinition.sides.VORNE.components') || []
    });
    if (this.get('model.moduldefinition.sides.VORNE.components')) {
      count = count + this.get('model.moduldefinition.sides.VORNE.components.length');
    }

    this.set('model.moduldefinition.sides.HINTEN', {
      type: 'HINTEN',
      minX: this.model.cabinet.restrictedAreas.HINTEN.LINKS,
      minY: this.model.cabinet.restrictedAreas.HINTEN.OBEN,
      maxX: this.model.moduldefinition.width - this.model.cabinet.restrictedAreas.HINTEN.RECHTS,
      maxY: this.model.moduldefinition.height - this.model.cabinet.restrictedAreas.HINTEN.OBEN,
      width: parseInt(this.model.moduldefinition.width),
      height: parseInt(this.model.moduldefinition.height),
      image: '',
      components: this.get('model.moduldefinition.sides.HINTEN.components') || []
    });
    if (this.get('model.moduldefinition.sides.HINTEN.components')) {
      count = count + this.get('model.moduldefinition.sides.HINTEN.components.length');
    }
    this.set('model.moduldefinition.sides.MONTAGEPLATTE', {
      type: 'MONTAGEPLATTE',
      minX: this.model.cabinet.restrictedAreas.MONTAGEPLATTE.LINKS,
      minY: this.model.cabinet.restrictedAreas.MONTAGEPLATTE.OBEN,
      maxX: this.model.moduldefinition.width - this.model.cabinet.restrictedAreas.MONTAGEPLATTE.RECHTS,
      maxY: this.model.moduldefinition.height - this.model.cabinet.restrictedAreas.MONTAGEPLATTE.OBEN,
      width: parseInt(this.model.moduldefinition.width),
      height: parseInt(this.model.moduldefinition.height),
      image: '',
      components: this.get('model.moduldefinition.sides.MONTAGEPLATTE.components') || []
    });
    if (this.get('model.moduldefinition.sides.MONTAGEPLATTE.components')) {
      count = count + this.get('model.moduldefinition.sides.MONTAGEPLATTE.components.length');
    }
    this.set('model.moduldefinition.sides.LINKS', {
      type: 'LINKS',
      minX: this.model.cabinet.restrictedAreas.LINKS.LINKS,
      minY: this.model.cabinet.restrictedAreas.LINKS.OBEN,
      maxX: this.model.moduldefinition.length - this.model.cabinet.restrictedAreas.LINKS.RECHTS,
      maxY: this.model.moduldefinition.height - this.model.cabinet.restrictedAreas.LINKS.OBEN,
      width: parseInt(this.model.moduldefinition.length),
      height: parseInt(this.model.moduldefinition.height),
      image: '',
      components: this.get('model.moduldefinition.sides.LINKS.components') || []
    });
    if (this.get('model.moduldefinition.sides.LINKS.components')) {
      count = count + this.get('model.moduldefinition.sides.LINKS.components.length');
    }
    this.set('model.moduldefinition.sides.RECHTS', {
      type: 'RECHTS',
      minX: this.model.cabinet.restrictedAreas.RECHTS.LINKS,
      minY: this.model.cabinet.restrictedAreas.RECHTS.OBEN,
      maxX: this.model.moduldefinition.length - this.model.cabinet.restrictedAreas.RECHTS.RECHTS,
      maxY: this.model.moduldefinition.height - this.model.cabinet.restrictedAreas.RECHTS.OBEN,
      width: parseInt(this.model.moduldefinition.length),
      height: parseInt(this.model.moduldefinition.height),
      image: '',
      components: this.get('model.moduldefinition.sides.RECHTS.components') || []
    });
    if (this.get('model.moduldefinition.sides.RECHTS.components')) {
      count = count + this.get('model.moduldefinition.sides.RECHTS.components.length');
    }
    this.set('model.moduldefinition.sides.OBEN', {
      type: 'OBEN',
      minX: this.model.cabinet.restrictedAreas.OBEN.LINKS,
      minY: this.model.cabinet.restrictedAreas.OBEN.UNTEN,
      maxX: this.model.moduldefinition.width - this.model.cabinet.restrictedAreas.OBEN.RECHTS,
      maxY: this.model.moduldefinition.length - this.model.cabinet.restrictedAreas.OBEN.OBEN,
      width: parseInt(this.model.moduldefinition.width),
      height: parseInt(this.model.moduldefinition.length),
      image: '',
      components: this.get('model.moduldefinition.sides.OBEN.components') || []
    });
    if (this.get('model.moduldefinition.sides.OBEN.components')) {
      count = count + this.get('model.moduldefinition.sides.OBEN.components.length');
    }
    this.set('model.moduldefinition.sides.UNTEN', {
      type: 'UNTEN',
      minX: this.model.cabinet.restrictedAreas.UNTEN.LINKS,
      minY: this.model.cabinet.restrictedAreas.UNTEN.UNTEN,
      maxX: this.model.moduldefinition.width - this.model.cabinet.restrictedAreas.UNTEN.RECHTS,
      maxY: this.model.moduldefinition.length - this.model.cabinet.restrictedAreas.UNTEN.OBEN,
      width: parseInt(this.model.moduldefinition.width),
      height: parseInt(this.model.moduldefinition.length),
      image: '',
      components: this.get('model.moduldefinition.sides.UNTEN.components') || []
    });
    if (this.get('model.moduldefinition.sides.UNTEN.components')) {
      count = count + this.get('model.moduldefinition.sides.UNTEN.components.length');
    }

    this.set('model.moduldefinition.cutouts', count);

    let model, lockAreas;
    if (this.get('model.moduldefinition.cabin') == 1) {
      model = 'model1_right';
    }
    if (this.get('model.moduldefinition.cabin') == 2) {
      model = 'model1_left';
    }
    if (this.get('model.moduldefinition.cabin') == 3) {
      model = 'model_2';
      lockAreas = [
        {
          x: this.get('model.moduldefinition.width') / 2 - 110,
          y: 0,
          w: 220,
          h: +this.get('model.moduldefinition.height')
        }
      ];
    }
    if (this.get('model.moduldefinition.cabin') == 4) {
      model = 'model_3';
    }

    if (this.get('model.moduldefinition.cabin') == 5) {
      model = 'model4_left';
      lockAreas = [
        {
          x: this.get('model.moduldefinition.width') / 3 - 100,
          y: 0,
          w: 200,
          h: +this.get('model.moduldefinition.height'),
          locks: 'right'
        },
        {
          x: (this.get('model.moduldefinition.width') / 3) * 2 - 75,
          y: 0,
          w: 175,
          h: +this.get('model.moduldefinition.height'),
          locks: 'right'
        }
      ];
    }

    if (this.get('model.moduldefinition.cabin') == 6) {
      model = 'model4_right';
      lockAreas = [
        {
          x: this.get('model.moduldefinition.width') / 3 - 100,
          y: 0,
          w: 200,
          h: +this.get('model.moduldefinition.height'),
          locks: 'left'
        },
        {
          x: (this.get('model.moduldefinition.width') / 3) * 2 - 75,
          y: 0,
          w: 175,
          h: +this.get('model.moduldefinition.height'),
          locks: 'right'
        }
      ];
    }

    const config = {
      id: this.get('model.name'),
      model: model,
      w: parseInt(this.get('model.moduldefinition.width')),
      h: parseInt(this.get('model.moduldefinition.height')),
      d: parseInt(this.get('model.moduldefinition.length')),
      cabinetId: this.get('model.name'),
      material: CabinetMaterial.EDELSTAHL,
      structure: CabinetStructure.GLATTGLAENZEND,
      color: this.get('model.color.rgb_hex') || '#cccccc',
      sides: [
        {
          ...this.get('model.moduldefinition.sides.VORNE'),
          lockAreas
        },
        {
          ...this.get('model.moduldefinition.sides.LINKS')
        },
        {
          ...this.get('model.moduldefinition.sides.RECHTS')
        },
        {
          ...this.get('model.moduldefinition.sides.OBEN')
        },
        {
          ...this.get('model.moduldefinition.sides.UNTEN')
        },
        {
          ...this.get('model.moduldefinition.sides.HINTEN')
        },
        {
          ...this.get('model.moduldefinition.sides.MONTAGEPLATTE')
        }
      ]
    };

    next(this, function () {
      if (this.model.moduldefinition.cabin === 1 || this.model.moduldefinition.cabin === 2) {
        this.set('montageplattebreite', this.model.moduldefinition.width - 80);
        this.set('montageplattehoehe', this.model.moduldefinition.height - 30);
      } else {
        this.set('montageplattebreite', this.model.moduldefinition.width - 80);
        this.set('montageplattehoehe', this.model.moduldefinition.height - 34);
      }
    });

    return config;
  }

  constructor() {
    super(...arguments);

    this.saveQueue$.pipe(debounceTime(300)).subscribe(() => {
      this.orderService.saveOrder(this.get('model'));
    });

    this.loadConfiguration();

    (window as any).FIMAB_CONFIGURATOR = {
      setCabinetConfiguration: () => {
        return true;
      },
      updateComponent: () => {
        return true;
      },
      addComponent: () => {
        return true;
      },
      deleteComponent: () => {
        return true;
      },
      showSurfaceSide: () => {
        return true;
      }
    };

    this.get('amplify').on('account:login', this, 'onAccountLogin');

    this.get('amplify').on('3DView:loaded', this, () => {
      this.set('isLoading', false);
    });
    if (this.get('authService.isAuthenticated')) {
      this.set('modalOpen', false);
    }
  }

  loadConfiguration() {
    const instanceJson = localStorage.getItem(localStoragePrefix + '.cabinetConfiguration');
    if (instanceJson) {
      const parsedConfiguration = JSON.parse(instanceJson);

      this._cabinetConfiguration = parsedConfiguration;
    }
  }

  saveConfiguration(key: string, data: object) {
    localStorage.setItem(localStoragePrefix + '.' + key, JSON.stringify(data));
  }

  @action
  onComponentCount(count: any) {
    this.set('model.moduldefinition.cutouts', count);
  }

  @action onInstanceChange(instances: ComponentInstance[]) {
    /*let length = 
    instances.sides.OBEN.components.length + 
    instances.sides.UNTEN.components.length + 
    instances.sides.LINKS.components.length + 
    instances.sides.RECHTS.components.length + 
    instances.sides.VORNE.components.length + 
    instances.sides.HINTEN.components.length + 
    instances.sides.MONTAGEPLATTE.components.length; 
    this.set('model.moduldefinition.cutouts', length);*/

    this.cacheOrderInternal(instances);
    this.saveQueue$.next(null);
  }

  async cacheOrderInternal(instances: any) {
    this.set('model.moduldefinition.sides.VORNE.components', instances.sides[0].components);
    this.set('model.moduldefinition.sides.LINKS.components', instances.sides[1].components);
    this.set('model.moduldefinition.sides.RECHTS.components', instances.sides[2].components);
    this.set('model.moduldefinition.sides.OBEN.components', instances.sides[3].components);
    this.set('model.moduldefinition.sides.UNTEN.components', instances.sides[4].components);
    this.set('model.moduldefinition.sides.HINTEN.components', instances.sides[5].components);
    this.set('model.moduldefinition.sides.MONTAGEPLATTE.components', instances.sides[6].components);
  }

  didRecieveAttributes() {
    if (this.get('step') > 1 && isEmpty(this.get('model.share'))) {
      this.set('step', 1);
    }
    if (this.get('model.moduldefinition.meta') !== null) {
      this.set('step', 2);
    }

    this.checkStepsDone();
  }

  willDestroy() {
    this.get('amplify').off('account:login');
    this.get('amplify').off('3DView:loaded');
  }

  onAccountLogin() {
    this.set('modalOpen', false);
  }

  async cacheCurrentConfiguration() {}

  @observes('stepsDone.step1', 'stepsDone.step2', 'stepsDone.step3')
  onChangeStepsDone() {
    this.amplify.Cache.setItem('stepsDone', this.stepsDone);
  }

  screenshotTimeout: any;
  @observes('step')
  async onStepChange() {
    let step = this.get('step');
    if (!isEmpty(this.get('model.moduldefinition')) && parseInt(step) > 1 && !isEmpty(this.get('model'))) {
      this.set('model.moduldefinition.currentStep', parseInt(step));
    }

    (window as any).ga('send', 'pageview', `step${step}`);
    this.checkStepsDone();
    await this.orderService.saveOrder(this.get('model'));

    if (this.get('step') == '4') {
      if (typeof (window as any).unityInstance !== 'undefined') {
        clearTimeout(this.screenshotTimeout);
        this.screenshotTimeout = setTimeout(() => {
          (window as any).unityInstance.SendMessage('[CabinetConfigurator]', 'RequestScreenshots', 85);
        }, 600);
      } else {
        // try again 5 secs after to see if unity loaded
        clearTimeout(this.screenshotTimeout);
        this.screenshotTimeout = setTimeout(() => {
          if (typeof (window as any).unityInstance !== 'undefined') {
            (window as any).unityInstance.SendMessage('[CabinetConfigurator]', 'RequestScreenshots', 85);
          }
        }, 5000);
      }
    } else {
      clearTimeout(this.screenshotTimeout);
    }
    if (this.get('step') == '1' || this.get('step') == '4') {
      this.amplify.trigger('3DView:deactivateCamera');
    } else {
      this.amplify.trigger('3DView:activateCamera');
    }
    this.cacheCurrentConfiguration();
  }

  @computed('model', 'model.ordernumber')
  get isOrderReadOnly() {
    return (
      (this.model.ordernumber !== undefined && this.model.ordernumber !== null && this.model.status == 'ORDERED') ||
      (this.model.status === 'NEU' && this.model.ordernumber !== null)
    );
  }

  @computed('model')
  get configurationUrl() {
    const step = this.model.moduldefinition.currentStep;
    return `${window.origin}/konfigurator/${this.model.id}${step !== undefined && step > 1 ? `?step=${step}` : ''}`;
  }

  @computed('showAccountCreate')
  get modalSize() {
    return this.showAccountCreate ? 'lg' : 'sm';
  }

  @action
  openModal() {
    this.set('modalOpen', true);
  }

  @action
  showNextAction() {
    this.set('showNext', true);
  }

  @action
  async next() {
    let currentStep = parseInt(this.step);

    await this.orderService.saveOrder(this.get('model'));
    if (currentStep == 4) {
      if (typeof (window as any).unityInstance !== 'undefined') {
        setTimeout(() => {
          (window as any).unityInstance.SendMessage('[CabinetConfigurator]', 'RequestScreenshots', 85);
        }, 800);
      }

      return;
    }
    currentStep = currentStep + 1;
    this.checkStepsDone();

    this.cacheCurrentConfiguration();
    set(this, 'step', currentStep.toString());
  }

  checkStepsDone() {
    let currentStep = parseInt(this.step);
    if (currentStep == 1) {
      this.set('stepsDone.step1', false);
      this.set('stepsDone.step2', false);
      this.set('stepsDone.step3', false);
    }
    if (currentStep == 2) {
      this.set('stepsDone.step1', true);
      this.set('stepsDone.step2', false);
      this.set('stepsDone.step3', false);
      this.toggleProperty('toggle');
    }
    if (currentStep == 3) {
      this.set('stepsDone.step1', true);
      this.set('stepsDone.step2', true);
      this.set('stepsDone.step3', false);
    }
    if (currentStep == 4) {
      this.set('stepsDone.step1', true);
      this.set('stepsDone.step2', true);
      this.set('stepsDone.step3', true);
    }
  }

  isUpdateable() {
    if (isEmpty(this.get('model.id'))) {
      return false;
    }
    return true;
  }
  isSaveable() {
    if (isEmpty(this.get('amplify.currentUser.id'))) {
      return false;
    }
    if (isEmpty(this.get('model.name'))) {
      return false;
    }
    return true;
  }

  @action
  async saveOrder() {
    await this.orderService.createOrder(this.get('model'));
    this.set('successMessageAngebot', true);
  }

  async cleanup() {
    this.amplify.Cache.removeItem('currentOrder');
    this.amplify.Cache.removeItem('stepsDone');

    this.set('stepsDone', {
      step1: false,
      step2: false,
      step3: false
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    konfigurator: Konfigurator;
  }
}
