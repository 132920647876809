import Component from '@ember/component';
import { action } from '@ember/object';

import { inject as service } from '@ember/service';
import OrderService from '../../services/order';
import CabinetService from '../../services/cabinet';
import { Cabinet } from '../../services/api-client';
import IntlService from 'ember-intl/services/intl';
import { classNames } from '@ember-decorators/component';

export interface CabinWithOptions extends Cabinet {
  options: {
    name: string;
    cabinet: Cabinet;
  }[];
}

@classNames('col-md-3')
export default class AppCabinPreviewCard extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service intl!: IntlService;
  @service('order') orderService!: OrderService;
  @service('cabinet') cabinetService!: CabinetService;

  cabinet!: CabinWithOptions;

  constructor() {
    super(...arguments);
  }

  hoverId = '';
  @action
  hoverOption(option: any) {
    this.set('hoverId', option.cabinet.migrationId);
  }

  didReceiveAttrs() {
    this.set('hoverId', this.cabinet.options?.[0]?.cabinet.migrationId);
  }

  @action
  async selectCabinet(cabinet: Cabinet) {
    this.toggleProperty('mode');
    delete (cabinet as any)['options'];
    this.set('model.cabinet', { ...cabinet });

    if (cabinet.migrationId == 'cjq856wif00p507443n0jvtlq') {
      this.set('model.moduldefinition.cabin', 1);
      this.set('model.moduldefinition.modelKey', 'model1_right');
    }
    if (cabinet.migrationId == 'cjq85767h00p80744srj85e7a') {
      this.set('model.moduldefinition.cabin', 2);
      this.set('model.moduldefinition.modelKey', 'model1_left');
    }
    if (cabinet.migrationId == 'cjq857a6500pb0744f2r3yza8') {
      this.set('model.moduldefinition.cabin', 3);
      this.set('model.moduldefinition.modelKey', 'model_2');
    }
    if (cabinet.migrationId == 'cjq857brf00pe0744sjw8x1p2') {
      this.set('model.moduldefinition.cabin', 4);
      this.set('model.moduldefinition.modelKey', 'model_3');
    }
    if (cabinet.migrationId == 'cjq857a6500pb0744f2r3yza7') {
      this.set('model.moduldefinition.cabin', 5);
      this.set('model.moduldefinition.modelKey', 'model4_right');
    }
    if (cabinet.migrationId == 'cjq857a6500pb0744f2r3yza6') {
      this.set('model.moduldefinition.cabin', 6);
      this.set('model.moduldefinition.modelKey', 'model4_left');
    }
    await this.orderService.saveOrder(this.get('model'));

    this.router.transitionTo('konfigurator', this.get('model'));
  }
}
