import Component from '@ember/component';
import { observes } from '@ember-decorators/object';
import { computed, action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AppExperienceBar extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  public experience: number = 549;
  public level: number = 1;
  public breakPoints: number[] = [0, 3000, 7000, 15000];

  constructor() {
    super(...arguments);
    setTimeout(() => {
      this.set('experience', 3000);
    }, 1000);
  }
}
